import React from 'react'
import { Layout, Stack, Main, PreFooter } from '@layout'
import PageTitle from '@components/PageTitle'
import Pagination from '@components/Pagination'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

import { Adsense } from '@components/Adsense/Adsense'


const PageCollection = ({ data: { posts, collectionInfo }, ...props }) => (
  <Layout {...props}>
    <Seo title={collectionInfo.name} description={collectionInfo.description} />
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2292291997787849"
     crossorigin="anonymous"></script>
    <Divider />
    <div>
        <Adsense />
      </div>
    <Stack effectProps={{ effect: 'fadeInDown' }}>
      <PageTitle
        header={collectionInfo.name}
        subheader={collectionInfo.title}
        running={collectionInfo.description}
        totalCount={posts.totalCount}
      />
    </Stack>
    <Divider />
    <div>
        <Adsense />
      </div>
    <Stack>
      <Main>
        {posts.nodes && (
          <CardList
            nodes={posts.nodes}
            variant={['horizontal-md', 'vertical']}
            columns={[1, 2, 3, 3]}
            omitCategory={
              props.pageContext &&
              props.pageContext.collectionType === 'category'
            }
          />
        )}
      </Main>
    </Stack>
    <Divider />
    <div>
        <Adsense />
      </div>
    <PreFooter>
      <Pagination {...posts.pageInfo} {...collectionInfo} />
    </PreFooter>
  </Layout>
)

export default PageCollection
